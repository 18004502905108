import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

interface Props {
  title?: string
  description?: string
  lang?: string
  keywords?: string[]
}

export const SEO: React.FC<Props> = ({ description = '', lang = 'nl', title = '' }) => {
  const { site, ogImage, appleTouchIcon } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
        ogImage: file(absolutePath: { regex: "/assets/social/og-image.jpg/" }) {
          childImageSharp {
            fixed(width: 279, height: 279) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        appleTouchIcon: file(absolutePath: { regex: "/assets/social/apple-touch-icon.png/" }) {
          childImageSharp {
            fixed(width: 180, height: 180) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  // eslint-disable-next-line no-nested-ternary
  const metaDescription = description?.length ? description : title.length ? title : site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title.length ? title : site.siteMetadata.title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: ogImage.childImageSharp.fixed.src,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ]}
    >
      <link rel="apple-touch-icon" href={appleTouchIcon.childImageSharp.fixed.src} />
    </Helmet>
  )
}
