import { Colors } from './colors'
import base from './base'

export { Color } from './colors'

export interface Theme {
  colors: Colors
  breakpoints: {
    DEFAULT: '(min-width: 768px)'
  }
}

export interface Themes {
  base: Theme
}

export interface WithTheme {
  theme: Theme
}

export default {
  base,
} as Themes
