import palette from './palette'
import { Colors } from './colors'
import { Theme } from './themes'

const colors: Colors = {
  text: palette.black,
  link: palette.orange,
  mainBackground: palette.lightGold,
  logo: palette.orange,
}

export const DEFAULT_BREAK_POINT = '(min-width: 768px)'

export default {
  colors,
  breakpoints: {
    DEFAULT: DEFAULT_BREAK_POINT,
  },
} as Theme
