const palette = {
  black: '#000000',
  white: '#ffffff',
  green: '#14603e',
  lightGreen: '#63898f',
  pink: '#f2c0c8',
  orange: '#e94e1b',
  gold: '#d8b44c',
  lightGold: '#ddd0af',
}

export default palette
