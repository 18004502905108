import { withTheme, createGlobalStyle } from 'styled-components'

import { Theme } from '../../styles/themes'

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  } 
  body {
    min-height: 100%;
    background-color: ${({ theme }) => (theme as Theme).colors.mainBackground};
    margin: 0;
    padding: 0;
  }
  img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
  }
`

export default withTheme(GlobalStyle)
